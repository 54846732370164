<script setup lang="ts">
import { useDark, useToggle } from '@vueuse/core'

const isDark = useDark()
const toggleDark = useToggle(isDark)
</script>

<template>
  <div
    class="w-12 cursor-pointer rounded-full border border-primary-500 transition-colors dark:border-secondary-50"
    :class="{ 'bg-secondary-950': isDark, 'bg-secondary-200': !isDark }"
    role="button"
    @click="toggleDark()"
  >
    <transition
      :name="isDark ? 'slide-right' : 'slide-left'"
      mode="out-in"
    >
      <span
        v-if="isDark"
        class="m-1 flex h-6 w-6 items-center justify-center rounded-full bg-primary-50"
        :class="{ 'translate-x-[65%]': isDark }"
      >
        <Icon
          class="text-primary-950"
          size="1rem"
          name="i-heroicons-moon"
        />
      </span>
      <span
        v-else
        class="m-1 flex h-6 w-6 items-center justify-center rounded-full bg-primary-50"
      >
        <Icon
          class="text-primary-950"
          size="1rem"
          name="i-heroicons-sun"
        />
      </span>
    </transition>
  </div>
</template>

<style scoped>
.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 0.5s ease;
}

.slide-right-enter {
  transform: translateX(0);
}

.slide-right-leave-to {
  transform: translateX(65%) rotateZ(360deg);
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 0.5s ease;
}

.slide-left-enter {
  transform: translateX(65%);
}

.slide-left-leave-to {
  transform: translateX(0) rotateZ(-360deg);
}
</style>
